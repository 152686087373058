import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import { PartialMessage } from "@bufbuild/protobuf";
import { useNavigate } from "react-router-dom";
import { BodyWrapper } from "../components/BodyWrapper";
import { IntegrationSettings, IntegrationSettingsData } from "../components/IntegrationSettings";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { GlobalError } from "../components/GlobalError";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { useClient } from "../api/client";
import { RegistryIntegrationSettings, RegistryIntegrationType } from "../pb/edgebit/platform/v1alpha/platform_pb";

export const PrivateRegistryDetail = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const { intgId } = useParams();
  const [settings, setSettings] = useState<IntegrationSettingsData[]>([]);
  const [formError, setFormError] = useState<string | null>(null);
  const [name, setName] = useState<string>("");
  const [secret, setSecret] = useState<string | undefined>(undefined);
  const [type, setType] = useState<RegistryIntegrationType | undefined>(undefined);
  const [scope, setScope] = useState<string | undefined>(undefined);
  const [address, setAddress] = useState<string | undefined>(undefined);

  const projs = Array.from(settings.entries());
  const navigate = useNavigate();

  const handleSettingsChange = (data: IntegrationSettingsData, projectIndex: number) => {
    settings[projectIndex] = data;
    setSettings([...settings]);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        // TODO: AuthenticatedApp() already does listProjects, use that.
        const projects = (await client.listProjects({})).projects;

        const install = await client.getRegistryIntegration({
          integrationId: intgId,
        });

        const settings = projects.map((p) => {
          // locate the project in the install
          const s = install.settings.find((s) => s.projectId === p.id);
          return {
            id: p.id,
            name: p.name,
            enabled: s !== undefined,
          };
        });

        setSettings(settings);
        setName(install.name);
        setType(install.type);
        setAddress(install.address);
        setScope(install.scope);
      } catch (err) {
        console.log(err);
        setConnectivity(false);
      }
    };

    fetch();
  }, [client, intgId]);

  const typeToPb = (type: RegistryIntegrationType | undefined): RegistryIntegrationType => {
    if (!type) {
      return RegistryIntegrationType.UNSPECIFIED;
    }

    switch (type) {
      case RegistryIntegrationType.NPM:
        return 1;
      default:
        return 0;
    }
  };

  interface TypeStringResult {
    name: string;
    placeholder: string;
    description: string;
  }

  const pbToTypeString = (type: RegistryIntegrationType | undefined): TypeStringResult => {
    let name: string;
    let placeholder = "Replace the secret with an updated value";
    let description = "Used as needed to access content stored in a private registry";

    switch (type) {
      case RegistryIntegrationType.NPM:
        name = "NPM";
        placeholder = "Enter an token to update the secret";
        description = "NPM-style auth token (_authToken) used to access the registry";
        break;
      default:
        name = "Unknown";
        break;
    }

    return { name, placeholder, description };
  };

  // Save Form
  const handleSave = async () => {
    setFormError(null);

    try {
      const pbSettings = settings.reduce((prev: PartialMessage<RegistryIntegrationSettings>[], s) => {
        if (s.enabled) {
          prev.push({
            projectId: s.id,
          });
        }
        return prev;
      }, []);

      const newIntg = {
        integrationId: intgId,
        name: name,
        secret: secret,
        type: typeToPb(type),
        settings: pbSettings,
        address: address,
        scope: scope,
      };

      await client.updateRegistryIntegration(newIntg);

      navigate(`/admin/integrations/`);
    } catch (err) {
      console.log(err);
      setConnectivity(false);
    }
  };

  useEffect(() => {
    document.title = "Configure Private Registry Integration";
  });

  return (
    <BodyWrapper>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Private Registry Settings
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "30px" }}>
        Secrets configured below will be used by EdgeBit as needed to access content stored in a private registry. This
        integration is only used in the projects enabled below.
      </Typography>

      <TableContainer component={Paper} sx={{ border: "1px solid #ddd", padding: "20px", marginBottom: "30px" }}>
        <Grid container spacing={2}>
          <Grid container item spacing={2} sx={{ marginBottom: "20px" }}>
            <Grid item xs={3}>
              <Typography variant="body1" sx={{ lineHeight: "40px", fontWeight: "600" }}>
                Secret Name:
              </Typography>
            </Grid>
            <Grid item xs={9} sm={6}>
              <TextField value={name} size="small" fullWidth onChange={(e) => setName(e.target.value)} />
            </Grid>
          </Grid>
          <Grid container item spacing={2} sx={{ marginBottom: "20px" }}>
            <Grid item xs={3}>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Secret Type:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {type && <Typography variant="body1">{pbToTypeString(type).name}</Typography>}
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" sx={{ lineHeight: "40px", fontWeight: "600" }}>
                Registry Address:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                size="small"
                multiline
                placeholder="registry.example.com"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <Typography variant="caption" sx={{ color: "#666" }}>
                Registry hostname (and port if needed), without protocol
              </Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" sx={{ lineHeight: "40px", fontWeight: "600" }}>
                Registry Scope:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                size="small"
                multiline
                placeholder="@example"
                value={scope}
                onChange={(e) => setScope(e.target.value)}
              />
              <Typography variant="caption" sx={{ color: "#666" }}>
                Scope under which the packages are published/accessed
              </Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" sx={{ lineHeight: "40px", fontWeight: "600" }}>
                Updated Secret Value:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                size="small"
                multiline
                placeholder={pbToTypeString(type).placeholder}
                onChange={(e) => setSecret(e.target.value)}
              />
              <Typography variant="caption" sx={{ color: "#666" }}>
                {pbToTypeString(type).description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </TableContainer>

      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "150px" }}>Project Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projs.map(([i, proj]) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ verticalAlign: "top", lineHeight: "38px" }}>{proj.name}</TableCell>
                <TableCell>
                  <IntegrationSettings data={proj} projectIndex={i} onChange={handleSettingsChange} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {formError && (
        <Alert style={{ marginTop: 11 }} severity="error">
          {formError}
        </Alert>
      )}

      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        onClick={handleSave}
        sx={{ marginTop: "20px", marginBottom: "0px" }}
      >
        Save Settings
      </EdgeBitPrimaryButton>
    </BodyWrapper>
  );
};
