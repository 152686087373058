// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file edgebit/platform/v1alpha/signup.proto (package edgebit.platform.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message edgebit.platform.v1alpha.CreateOrgRequest
 */
export class CreateOrgRequest extends Message<CreateOrgRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  /**
   * @generated from field: string org_name = 3;
   */
  orgName = "";

  constructor(data?: PartialMessage<CreateOrgRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateOrgRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "org_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrgRequest {
    return new CreateOrgRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrgRequest {
    return new CreateOrgRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrgRequest {
    return new CreateOrgRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrgRequest | PlainMessage<CreateOrgRequest> | undefined, b: CreateOrgRequest | PlainMessage<CreateOrgRequest> | undefined): boolean {
    return proto3.util.equals(CreateOrgRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateOrgResponse
 */
export class CreateOrgResponse extends Message<CreateOrgResponse> {
  /**
   * @generated from field: string hostname = 1;
   */
  hostname = "";

  constructor(data?: PartialMessage<CreateOrgResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateOrgResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hostname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrgResponse {
    return new CreateOrgResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrgResponse {
    return new CreateOrgResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrgResponse {
    return new CreateOrgResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrgResponse | PlainMessage<CreateOrgResponse> | undefined, b: CreateOrgResponse | PlainMessage<CreateOrgResponse> | undefined): boolean {
    return proto3.util.equals(CreateOrgResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.TestOrgNameRequest
 */
export class TestOrgNameRequest extends Message<TestOrgNameRequest> {
  /**
   * @generated from field: string org_name = 1;
   */
  orgName = "";

  constructor(data?: PartialMessage<TestOrgNameRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.TestOrgNameRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "org_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TestOrgNameRequest {
    return new TestOrgNameRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TestOrgNameRequest {
    return new TestOrgNameRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TestOrgNameRequest {
    return new TestOrgNameRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TestOrgNameRequest | PlainMessage<TestOrgNameRequest> | undefined, b: TestOrgNameRequest | PlainMessage<TestOrgNameRequest> | undefined): boolean {
    return proto3.util.equals(TestOrgNameRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.TestOrgNameResponse
 */
export class TestOrgNameResponse extends Message<TestOrgNameResponse> {
  /**
   * @generated from field: bool available = 1;
   */
  available = false;

  constructor(data?: PartialMessage<TestOrgNameResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.TestOrgNameResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TestOrgNameResponse {
    return new TestOrgNameResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TestOrgNameResponse {
    return new TestOrgNameResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TestOrgNameResponse {
    return new TestOrgNameResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TestOrgNameResponse | PlainMessage<TestOrgNameResponse> | undefined, b: TestOrgNameResponse | PlainMessage<TestOrgNameResponse> | undefined): boolean {
    return proto3.util.equals(TestOrgNameResponse, a, b);
  }
}

