import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BodyWrapper } from "../components/BodyWrapper";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { GlobalError } from "../components/GlobalError";
import { IntegrationsTable } from "../components/IntegrationsTable";
import { useClient } from "../api/client";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { Integration } from "../components/IntegrationsTable";
import { IntegrationType } from "../pb/edgebit/platform/v1alpha/platform_pb";

export const IntegrationsList = () => {
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const [integrations, setIntegrations] = useState<Integration[]>([]);

  const client = useClient(EdgeBitPublicAPIService);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Integrations - EdgeBit";
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const integrations = (await client.listIntegrations({})).integrations;
        setIntegrations(
          integrations.map((i) => {
            switch (i.type) {
              case IntegrationType.GITHUB:
                return {
                  id: i.id,
                  type_: "GitHub",
                  icon: "https://avatars.githubusercontent.com/in/15368?s=80&v=4",
                  account: i.account,
                  avatar: "https://avatars.githubusercontent.com/" + i.account,
                  status: "Connected",
                  details: `/admin/github/${i.id}`,
                };

              case IntegrationType.JIRA:
                return {
                  id: i.id,
                  type_: "Jira",
                  icon: "/logo-jira.svg",
                  avatar: i.avatarUrl,
                  account: i.account,
                  status: i.lastError.length === 0 ? "Connected" : "Error: " + i.lastError,
                  details: `/admin/jira/${i.id}`,
                };

              case IntegrationType.VANTA:
                return {
                  id: i.id,
                  type_: "Vanta",
                  icon: "/logo-vanta.svg",
                  avatar: i.avatarUrl,
                  account: i.account,
                  status: i.lastError.length === 0 ? "Connected" : "Error: " + i.lastError,
                  details: `/admin/vanta/${i.id}`,
                };

              case IntegrationType.SLACK:
                return {
                  id: i.id,
                  type_: "Slack",
                  icon: "/logo-slack.svg",
                  avatar: i.avatarUrl,
                  account: i.account,
                  status: i.lastError.length === 0 ? "Connected" : "Error: " + i.lastError,
                  details: `/admin/slack/${i.id}`,
                };

              case IntegrationType.AWS:
                return {
                  id: i.id,
                  type_: "AWS",
                  icon: "/logo-aws.svg",
                  avatar: i.avatarUrl,
                  account: i.account,
                  status:
                    i.lastError.length === 0
                      ? i.account === "Unconfigured"
                        ? "Unconfigured"
                        : "Connected"
                      : "Error: " + i.lastError,
                  details: `/admin/aws/${i.id}`,
                };

              case IntegrationType.REGISTRY:
                return {
                  id: i.id,
                  type_: "Registry",
                  icon: "/logo-registry.svg",
                  avatar: i.avatarUrl,
                  account: i.account,
                  status:
                    i.lastError.length === 0
                      ? i.account === "Unconfigured"
                        ? "Unconfigured"
                        : "Connected"
                      : "Error: " + i.lastError,
                  details: `/admin/registries/${i.id}`,
                };

              default:
                return {
                  id: i.id,
                  type_: "Unknown",
                  icon: "",
                  account: i.account,
                  avatar: "",
                  status: "???",
                  details: "",
                };
            }
          }),
        );
      } catch (err) {
        console.log(err);
        setConnectivity(false);
      }
    };

    fetch();
  }, [client]);

  return (
    <BodyWrapper>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom sx={{ marginTop: "40px" }}>
        Integrations
      </Typography>
      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        sx={{ marginTop: "20px", marginBottom: "0px" }}
        onClick={() => {
          navigate("/admin/integrations/add");
        }}
      >
        Add Integration
      </EdgeBitPrimaryButton>
      <IntegrationsTable data={integrations} />
    </BodyWrapper>
  );
};
