// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file edgebit/platform/v1alpha/login.proto (package edgebit.platform.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum edgebit.platform.v1alpha.LoginMethod
 */
export enum LoginMethod {
  /**
   * @generated from enum value: LOGIN_METHOD_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LOGIN_METHOD_PASSWORD = 1;
   */
  PASSWORD = 1,

  /**
   * @generated from enum value: LOGIN_METHOD_OIDC = 2;
   */
  OIDC = 2,

  /**
   * @generated from enum value: LOGIN_METHOD_GOOGLE = 3;
   */
  GOOGLE = 3,

  /**
   * @generated from enum value: LOGIN_METHOD_OKTA_OIDC = 4;
   */
  OKTA_OIDC = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(LoginMethod)
proto3.util.setEnumType(LoginMethod, "edgebit.platform.v1alpha.LoginMethod", [
  { no: 0, name: "LOGIN_METHOD_UNSPECIFIED" },
  { no: 1, name: "LOGIN_METHOD_PASSWORD" },
  { no: 2, name: "LOGIN_METHOD_OIDC" },
  { no: 3, name: "LOGIN_METHOD_GOOGLE" },
  { no: 4, name: "LOGIN_METHOD_OKTA_OIDC" },
]);

/**
 * @generated from message edgebit.platform.v1alpha.GetLoginMethodRequest
 */
export class GetLoginMethodRequest extends Message<GetLoginMethodRequest> {
  constructor(data?: PartialMessage<GetLoginMethodRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetLoginMethodRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLoginMethodRequest {
    return new GetLoginMethodRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLoginMethodRequest {
    return new GetLoginMethodRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLoginMethodRequest {
    return new GetLoginMethodRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLoginMethodRequest | PlainMessage<GetLoginMethodRequest> | undefined, b: GetLoginMethodRequest | PlainMessage<GetLoginMethodRequest> | undefined): boolean {
    return proto3.util.equals(GetLoginMethodRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetLoginMethodResponse
 */
export class GetLoginMethodResponse extends Message<GetLoginMethodResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.LoginMethod method = 1;
   */
  method = LoginMethod.UNSPECIFIED;

  constructor(data?: PartialMessage<GetLoginMethodResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetLoginMethodResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "method", kind: "enum", T: proto3.getEnumType(LoginMethod) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLoginMethodResponse {
    return new GetLoginMethodResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLoginMethodResponse {
    return new GetLoginMethodResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLoginMethodResponse {
    return new GetLoginMethodResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLoginMethodResponse | PlainMessage<GetLoginMethodResponse> | undefined, b: GetLoginMethodResponse | PlainMessage<GetLoginMethodResponse> | undefined): boolean {
    return proto3.util.equals(GetLoginMethodResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.PasswordLoginRequest
 */
export class PasswordLoginRequest extends Message<PasswordLoginRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<PasswordLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.PasswordLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PasswordLoginRequest {
    return new PasswordLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PasswordLoginRequest {
    return new PasswordLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PasswordLoginRequest {
    return new PasswordLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PasswordLoginRequest | PlainMessage<PasswordLoginRequest> | undefined, b: PasswordLoginRequest | PlainMessage<PasswordLoginRequest> | undefined): boolean {
    return proto3.util.equals(PasswordLoginRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.PasswordLoginResponse
 */
export class PasswordLoginResponse extends Message<PasswordLoginResponse> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<PasswordLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.PasswordLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PasswordLoginResponse {
    return new PasswordLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PasswordLoginResponse {
    return new PasswordLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PasswordLoginResponse {
    return new PasswordLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PasswordLoginResponse | PlainMessage<PasswordLoginResponse> | undefined, b: PasswordLoginResponse | PlainMessage<PasswordLoginResponse> | undefined): boolean {
    return proto3.util.equals(PasswordLoginResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.OIDCCallbackRequest
 */
export class OIDCCallbackRequest extends Message<OIDCCallbackRequest> {
  /**
   * @generated from field: string query_string = 1;
   */
  queryString = "";

  constructor(data?: PartialMessage<OIDCCallbackRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.OIDCCallbackRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query_string", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OIDCCallbackRequest {
    return new OIDCCallbackRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OIDCCallbackRequest {
    return new OIDCCallbackRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OIDCCallbackRequest {
    return new OIDCCallbackRequest().fromJsonString(jsonString, options);
  }

  static equals(a: OIDCCallbackRequest | PlainMessage<OIDCCallbackRequest> | undefined, b: OIDCCallbackRequest | PlainMessage<OIDCCallbackRequest> | undefined): boolean {
    return proto3.util.equals(OIDCCallbackRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.OIDCCallbackResponse
 */
export class OIDCCallbackResponse extends Message<OIDCCallbackResponse> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string next = 2;
   */
  next = "";

  constructor(data?: PartialMessage<OIDCCallbackResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.OIDCCallbackResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "next", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OIDCCallbackResponse {
    return new OIDCCallbackResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OIDCCallbackResponse {
    return new OIDCCallbackResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OIDCCallbackResponse {
    return new OIDCCallbackResponse().fromJsonString(jsonString, options);
  }

  static equals(a: OIDCCallbackResponse | PlainMessage<OIDCCallbackResponse> | undefined, b: OIDCCallbackResponse | PlainMessage<OIDCCallbackResponse> | undefined): boolean {
    return proto3.util.equals(OIDCCallbackResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.APIAccessTokenLoginRequest
 */
export class APIAccessTokenLoginRequest extends Message<APIAccessTokenLoginRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<APIAccessTokenLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.APIAccessTokenLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): APIAccessTokenLoginRequest {
    return new APIAccessTokenLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): APIAccessTokenLoginRequest {
    return new APIAccessTokenLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): APIAccessTokenLoginRequest {
    return new APIAccessTokenLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: APIAccessTokenLoginRequest | PlainMessage<APIAccessTokenLoginRequest> | undefined, b: APIAccessTokenLoginRequest | PlainMessage<APIAccessTokenLoginRequest> | undefined): boolean {
    return proto3.util.equals(APIAccessTokenLoginRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.APIAccessTokenLoginResponse
 */
export class APIAccessTokenLoginResponse extends Message<APIAccessTokenLoginResponse> {
  /**
   * @generated from field: string session_token = 1;
   */
  sessionToken = "";

  /**
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  constructor(data?: PartialMessage<APIAccessTokenLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.APIAccessTokenLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): APIAccessTokenLoginResponse {
    return new APIAccessTokenLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): APIAccessTokenLoginResponse {
    return new APIAccessTokenLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): APIAccessTokenLoginResponse {
    return new APIAccessTokenLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: APIAccessTokenLoginResponse | PlainMessage<APIAccessTokenLoginResponse> | undefined, b: APIAccessTokenLoginResponse | PlainMessage<APIAccessTokenLoginResponse> | undefined): boolean {
    return proto3.util.equals(APIAccessTokenLoginResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ExchangeInviteTokenRequest
 */
export class ExchangeInviteTokenRequest extends Message<ExchangeInviteTokenRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<ExchangeInviteTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ExchangeInviteTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExchangeInviteTokenRequest {
    return new ExchangeInviteTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExchangeInviteTokenRequest {
    return new ExchangeInviteTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExchangeInviteTokenRequest {
    return new ExchangeInviteTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ExchangeInviteTokenRequest | PlainMessage<ExchangeInviteTokenRequest> | undefined, b: ExchangeInviteTokenRequest | PlainMessage<ExchangeInviteTokenRequest> | undefined): boolean {
    return proto3.util.equals(ExchangeInviteTokenRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ExchangeInviteTokenResponse
 */
export class ExchangeInviteTokenResponse extends Message<ExchangeInviteTokenResponse> {
  /**
   * @generated from field: string session_token = 1;
   */
  sessionToken = "";

  constructor(data?: PartialMessage<ExchangeInviteTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ExchangeInviteTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExchangeInviteTokenResponse {
    return new ExchangeInviteTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExchangeInviteTokenResponse {
    return new ExchangeInviteTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExchangeInviteTokenResponse {
    return new ExchangeInviteTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ExchangeInviteTokenResponse | PlainMessage<ExchangeInviteTokenResponse> | undefined, b: ExchangeInviteTokenResponse | PlainMessage<ExchangeInviteTokenResponse> | undefined): boolean {
    return proto3.util.equals(ExchangeInviteTokenResponse, a, b);
  }
}

