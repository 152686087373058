// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file edgebit/platform/v1alpha/worker_job_debug.proto (package edgebit.platform.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message edgebit.platform.v1alpha.StreamJobLogsRequest
 */
export class StreamJobLogsRequest extends Message<StreamJobLogsRequest> {
  /**
   * @generated from field: string job_id = 1;
   */
  jobId = "";

  constructor(data?: PartialMessage<StreamJobLogsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.StreamJobLogsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StreamJobLogsRequest {
    return new StreamJobLogsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StreamJobLogsRequest {
    return new StreamJobLogsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StreamJobLogsRequest {
    return new StreamJobLogsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StreamJobLogsRequest | PlainMessage<StreamJobLogsRequest> | undefined, b: StreamJobLogsRequest | PlainMessage<StreamJobLogsRequest> | undefined): boolean {
    return proto3.util.equals(StreamJobLogsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.StreamJobLogsResponse
 */
export class StreamJobLogsResponse extends Message<StreamJobLogsResponse> {
  /**
   * @generated from oneof edgebit.platform.v1alpha.StreamJobLogsResponse.response
   */
  response: {
    /**
     * @generated from field: edgebit.platform.v1alpha.JobLogLine log_line = 1;
     */
    value: JobLogLine;
    case: "logLine";
  } | {
    /**
     * @generated from field: edgebit.platform.v1alpha.JobResult result = 2;
     */
    value: JobResult;
    case: "result";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<StreamJobLogsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.StreamJobLogsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "log_line", kind: "message", T: JobLogLine, oneof: "response" },
    { no: 2, name: "result", kind: "message", T: JobResult, oneof: "response" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StreamJobLogsResponse {
    return new StreamJobLogsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StreamJobLogsResponse {
    return new StreamJobLogsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StreamJobLogsResponse {
    return new StreamJobLogsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StreamJobLogsResponse | PlainMessage<StreamJobLogsResponse> | undefined, b: StreamJobLogsResponse | PlainMessage<StreamJobLogsResponse> | undefined): boolean {
    return proto3.util.equals(StreamJobLogsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.JobLogLine
 */
export class JobLogLine extends Message<JobLogLine> {
  /**
   * @generated from field: string raw_line = 1;
   */
  rawLine = "";

  constructor(data?: PartialMessage<JobLogLine>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.JobLogLine";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "raw_line", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JobLogLine {
    return new JobLogLine().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JobLogLine {
    return new JobLogLine().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JobLogLine {
    return new JobLogLine().fromJsonString(jsonString, options);
  }

  static equals(a: JobLogLine | PlainMessage<JobLogLine> | undefined, b: JobLogLine | PlainMessage<JobLogLine> | undefined): boolean {
    return proto3.util.equals(JobLogLine, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.JobResult
 */
export class JobResult extends Message<JobResult> {
  /**
   * @generated from oneof edgebit.platform.v1alpha.JobResult.result
   */
  result: {
    /**
     * @generated from field: edgebit.platform.v1alpha.SuccessResult success = 1;
     */
    value: SuccessResult;
    case: "success";
  } | {
    /**
     * @generated from field: edgebit.platform.v1alpha.FailureResult failure = 2;
     */
    value: FailureResult;
    case: "failure";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<JobResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.JobResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "message", T: SuccessResult, oneof: "result" },
    { no: 2, name: "failure", kind: "message", T: FailureResult, oneof: "result" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JobResult {
    return new JobResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JobResult {
    return new JobResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JobResult {
    return new JobResult().fromJsonString(jsonString, options);
  }

  static equals(a: JobResult | PlainMessage<JobResult> | undefined, b: JobResult | PlainMessage<JobResult> | undefined): boolean {
    return proto3.util.equals(JobResult, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SuccessResult
 */
export class SuccessResult extends Message<SuccessResult> {
  constructor(data?: PartialMessage<SuccessResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SuccessResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuccessResult {
    return new SuccessResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuccessResult {
    return new SuccessResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuccessResult {
    return new SuccessResult().fromJsonString(jsonString, options);
  }

  static equals(a: SuccessResult | PlainMessage<SuccessResult> | undefined, b: SuccessResult | PlainMessage<SuccessResult> | undefined): boolean {
    return proto3.util.equals(SuccessResult, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.FailureResult
 */
export class FailureResult extends Message<FailureResult> {
  /**
   * @generated from field: string message = 1;
   */
  message = "";

  constructor(data?: PartialMessage<FailureResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.FailureResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FailureResult {
    return new FailureResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FailureResult {
    return new FailureResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FailureResult {
    return new FailureResult().fromJsonString(jsonString, options);
  }

  static equals(a: FailureResult | PlainMessage<FailureResult> | undefined, b: FailureResult | PlainMessage<FailureResult> | undefined): boolean {
    return proto3.util.equals(FailureResult, a, b);
  }
}

