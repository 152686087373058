import React from "react";
import { useState, useEffect } from "react";
import { Box, Link, Collapse } from "@mui/material";
import * as Diff2Html from "diff2html";
import "diff2html/bundles/css/diff2html.min.css";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Folder from "@mui/icons-material/Folder";
import {
  ProposalDiffPackage,
  ProposalDiffFile,
  ProposalDiffFileStatus,
  ProposalDiffFileChanges,
  ProposalCombinedPackage,
} from "../features/proposalDetailSlice";

interface DiffFilesProps {
  package: ProposalDiffPackage;
  pkgIntel: ProposalCombinedPackage[];
}

type Directory = {
  [key: string]: Directory | ProposalDiffFile;
};

export function DiffFiles(props: DiffFilesProps) {
  let directories = buildDirectoryStructure(props.package.files);
  let baseURL = buildBaseURL(props.package.name, props.pkgIntel);

  return (
    <Box sx={{ marginLeft: "-17px" }}>
      <DirectoryDisplay directory={directories} rootPath="." baseURL={baseURL} />
    </Box>
  );
}

function DirectoryDisplay(props: {
  directory: Directory | ProposalDiffFile;
  rootPath?: string;
  baseURL: string | undefined;
}) {
  const [open, setOpen] = useState<string[]>([]);
  const [startedOpen, setStartedOpen] = useState<string[]>([]);

  // Set the open state initially based on the directory content and path
  useEffect(() => {
    const visibleDirectories = Object.entries(props.directory).filter(([name, content]) => {
      if (isDirectory(content)) {
        // Check if directory itself or any of its contents are visible
        const hasVisibleContent = (dir: Directory | ProposalDiffFile): boolean => {
          if (!isDirectory(dir)) {
            return !statusIsHidden((dir as ProposalDiffFile).status);
          }
          return Object.values(dir).some((item) => {
            if (isDirectory(item)) {
              return hasVisibleContent(item);
            }
            return !statusIsHidden(item.status);
          });
        };
        return hasVisibleContent(content as Directory);
      }
      return false;
    });

    const visiblePaths = visibleDirectories.map(([name]) => name);
    setOpen(visiblePaths);
    setStartedOpen(visiblePaths);
  }, [props.directory, props.rootPath]);

  const handleToggle = (name: string) => {
    setOpen((prevOpen) => {
      if (prevOpen.includes(name)) {
        return prevOpen.filter((item) => item !== name);
      }
      return [...prevOpen, name];
    });
  };

  const calculateDirectoryChanges = (
    dir: Directory | ProposalDiffFile,
    baseURL: string | undefined,
  ): ProposalDiffFileChanges => {
    if (!isDirectory(dir)) {
      return (dir as ProposalDiffFile).changes || { added: 0, removed: 0, changed: 0 };
    }

    return Object.values(dir).reduce(
      (acc, item) => {
        const changes = calculateDirectoryChanges(item, baseURL);
        return {
          added: (acc.added || 0) + (changes.added || 0),
          removed: (acc.removed || 0) + (changes.removed || 0),
          changed: (acc.changed || 0) + (changes.changed || 0),
        };
      },
      { added: 0, removed: 0, changed: 0 },
    );
  };

  return (
    <>
      {Object.entries(props.directory).map(([name, content]) => (
        <Box key={name} sx={{ marginLeft: "17px" }}>
          {isDirectory(content) ? (
            <>
              <Box onClick={() => handleToggle(name)}>
                {open.includes(name) ? (
                  <KeyboardArrowDownIcon sx={{ fontSize: "15px", verticalAlign: "middle", marginRight: "3px" }} />
                ) : (
                  <KeyboardArrowRightIcon
                    sx={{ fontSize: "15px", verticalAlign: "middle", marginRight: "3px", color: "#999" }}
                  />
                )}
                <Folder
                  sx={{
                    fontSize: "17px",
                    verticalAlign: "middle",
                    marginRight: "3px",
                    color: startedOpen.includes(name) ? "#FF9900" : "#ccc",
                  }}
                />
                <Box
                  sx={{
                    display: "inline-block",
                    fontSize: "14px",
                    color: startedOpen.includes(name) ? "#000" : "#999",
                  }}
                >
                  {name}
                </Box>
                <DiffChanges changes={calculateDirectoryChanges(content, props.baseURL)} />
              </Box>
              <Collapse in={open.includes(name)} timeout={0}>
                <DirectoryDisplay
                  directory={content}
                  rootPath={props.rootPath ? `${props.rootPath}/${name}` : name}
                  baseURL={props.baseURL}
                />
              </Collapse>
            </>
          ) : (
            <FileDisplay file={content as ProposalDiffFile} baseURL={props.baseURL} />
          )}
        </Box>
      ))}
    </>
  );
}

function FileDisplay(props: { file: ProposalDiffFile; baseURL: string | undefined }) {
  const [open, setOpen] = useState(!statusIsHidden(props.file.status));
  const [startedOpen] = useState(!statusIsHidden(props.file.status));

  const handleToggle = () => {
    setOpen(!open);
  };

  const filename = props.file.path.split("/").pop();
  const diffs: string[] = props.file.diffs;

  return (
    <>
      <Box onClick={handleToggle} sx={{ display: "inline-block" }}>
        {open ? (
          <KeyboardArrowDownIcon sx={{ fontSize: "15px", verticalAlign: "middle", marginRight: "3px" }} />
        ) : (
          <KeyboardArrowRightIcon
            sx={{ fontSize: "15px", verticalAlign: "middle", marginRight: "3px", color: "#999" }}
          />
        )}{" "}
      </Box>
      <InsertDriveFileOutlinedIcon
        sx={{ fontSize: "15px", verticalAlign: "middle", marginRight: "3px", color: startedOpen ? "#000" : "#999" }}
      />
      {props.baseURL ? (
        <Link
          href={props.baseURL + props.file.path}
          sx={{ fontSize: "14px", color: startedOpen ? "#6096FF" : "#999" }}
          target="_blank"
        >
          {filename}
        </Link>
      ) : (
        <Box sx={{ fontSize: "14px", color: startedOpen ? "#000" : "#999" }}>{filename}</Box>
      )}
      <DiffChanges changes={props.file.changes} />

      <Collapse in={open} timeout={0}>
        {diffs.map((diff, index) => (
          <Box key={index} sx={{ border: "1px solid #ccc", borderRadius: "3px", marginTop: "5px", marginLeft: "20px" }}>
            <Diff diffText={diff} limitHeight={false} />
          </Box>
        ))}
      </Collapse>
    </>
  );
}

function DiffChanges(props: { changes: ProposalDiffFileChanges | undefined }) {
  let addedLines = props.changes?.added ? "+".repeat(props.changes.added) : "";
  addedLines = addedLines + (props.changes?.changed ? "+".repeat(props.changes.changed) : "");
  let removedLines = props.changes?.removed ? "-".repeat(props.changes.removed) : "";

  if (addedLines.length > 10) {
    addedLines = "+".repeat(10);
  }
  if (removedLines.length > 10) {
    removedLines = "-".repeat(10);
  }

  return (
    <Box sx={{ display: "inline-block", fontSize: "12px", marginLeft: "30px", letterSpacing: "0.1em" }}>
      <Box sx={{ fontWeight: "600", color: "green", display: "inline-block", padding: "0 1px" }}>{addedLines}</Box>
      <Box sx={{ fontWeight: "600", color: "red", display: "inline-block", padding: "0 1px" }}>{removedLines}</Box>
    </Box>
  );
}

export function Diff(props: { diffText: string; limitHeight: boolean }) {
  let diffConfig: Diff2Html.Diff2HtmlConfig = {
    drawFileList: false,
    matching: "words",
    diffStyle: "char",
  };
  let outputHtml = Diff2Html.html(props.diffText, diffConfig);

  if (props.diffText === "") {
    return <Box sx={{ color: "#999", padding: "5px" }}>No diff available for this symbol</Box>;
  }

  let overflowStyle = {};
  if (props.limitHeight) {
    overflowStyle = {
      maxHeight: "200px",
    };
  }

  return (
    <Box
      sx={{
        "--d2h-change-ins-color": "#E5FAE4",
        "--d2h-change-del-color": "#FCECEA",
        "--d2h-ins-highlight-bg-color": "#BAECBF",
        "--d2h-del-highlight-bg-color": "#F7C4C1",
        fontSize: "10px",
        fontFamily: "monospace",
        "& pre": {
          margin: 0,
        },
        "& .d2h-wrapper": {
          ...overflowStyle,
          overflow: "scroll",
        },
        "& .d2h-diff-table": {
          fontSize: "10px",
        },
        "& .d2h-file-wrapper": {
          border: "0px",
          marginBottom: "0px",
        },
        "& .d2h-file-header": {
          display: "none",
        },
        "& .d2h-code-line": {
          padding: "0 5px",
        },
        "& .d2h-code-linenumber": {
          display: "none",
        },
        "& .d2h-file-diff": {
          overflow: "visible",
        },
      }}
      dangerouslySetInnerHTML={{ __html: outputHtml }}
    ></Box>
  );
}

function buildDirectoryStructure(files: ProposalDiffFile[]): Directory {
  const root: Directory = {};

  files.forEach((file) => {
    const parts: string[] = file.path.split("/"); // Split the path into segments
    let currentDir = root;

    for (let part of parts.slice(0, -1)) {
      if (!currentDir[part]) {
        currentDir[part] = {};
      }
      currentDir = currentDir[part] as Directory;
    }
    currentDir[parts[parts.length - 1]] = file;
  });

  // add up changes for files in each directory

  return root;
}

function isDirectory(content: Directory | ProposalDiffFile): boolean {
  return typeof content === "object" && !("path" in content);
}

function statusIsHidden(status: ProposalDiffFileStatus): boolean {
  return status === 2;
}

function buildBaseURL(targetPackageName: string, pkgIntel: ProposalCombinedPackage[]): string | undefined {
  const pkg = pkgIntel.find((pkg) => pkg.name === targetPackageName);
  if (!pkg?.branchUrl) return undefined;
  return pkg.branchUrl.replace(pkg.currentVersion, pkg.updatedVersion);
}
