import { Box, Tooltip } from "@mui/material";

export function AIInfo() {
  return (
    <Tooltip title="Summarization made by AI, with inputs based on Git history and static analysis." placement="top">
      <Box
        sx={{
          display: "inline-block",
          marginLeft: "5px",
          marginRight: "5px",
          border: "1px solid #ccc",
          lineHeight: "11px",
          fontSize: "10px",
          fontWeight: "bold",
          color: "#999",
          borderRadius: "3px",
          padding: "0 3px",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        AI
      </Box>
    </Tooltip>
  );
}
