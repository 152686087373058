import { Typography, Container, Box, Button, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { BodyWrapper } from "../components/BodyWrapper";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { useClient } from "../api/client";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { RegistryIntegrationType } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { Code, ConnectError } from "@bufbuild/connect";
import { appReflector } from "../utils/next_urls";
import { FeatureFlagGate } from "../components/FeatureFlagGate";
import { useNavigate } from "react-router-dom";

export const IntegrationsAdd = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const githubNextUrl = () => {
    const proto = window.location.protocol;
    const host = window.location.hostname;
    const port = window.location.port;
    return encodeURIComponent(`${proto}//${host}:${port}/github-complete-install/admin`);
  };

  const jiraNextUrl = () => {
    const proto = window.location.protocol;
    const host = window.location.hostname;
    const port = window.location.port;
    return encodeURIComponent(`${proto}//${host}:${port}/jira-complete-install`);
    // https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=gIl0tO7oRVbeMEDbMrp7Mkio1DbcElt0&scope=write%3Ajira-work%20read%3Ajira-work&redirect_uri=https%3A%2F%2Fapp.edgebit.io%2Fjira%2Fwebhook&state=${YOUR_USER_BOUND_VALUE}&response_type=code&prompt=consent
  };

  const vantaNextUrl = () => {
    const proto = window.location.protocol;
    const host = window.location.hostname;
    const port = window.location.port;
    return encodeURIComponent(`${proto}//${host}:${port}/vanta-complete-install`);
  };

  const slackNextUrl = () => {
    const proto = window.location.protocol;
    const host = window.location.hostname;
    const port = window.location.port;
    return encodeURIComponent(`${proto}//${host}:${port}/slack-complete-install`);
  };

  const installGitHub = async () => {
    window.location.href = `${appReflector()}/github/install?next=${githubNextUrl()}`;
  };

  const installVanta = async () => {
    try {
      // grab the HMAC protected state (encodes OrgID) before hitting the reflector
      const state = (await client.beginVantaInstall({})).state;
      const url = `${appReflector()}/integrations/vanta/install?state=${state}&next=${vantaNextUrl()}`;
      window.location.href = url;
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.InvalidArgument) {
        setError(err.rawMessage);
      } else {
        setError("Unknown error");
      }
    }
  };

  const installSlack = async () => {
    try {
      // grab the HMAC protected state (encodes OrgID) before hitting the reflector
      const state = (await client.beginSlackInstall({})).state;
      const url = `${appReflector()}/integrations/slack/install?state=${state}&next=${slackNextUrl()}`;
      window.location.href = url;
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.InvalidArgument) {
        setError(err.rawMessage);
      } else {
        setError("Unknown error");
      }
    }
  };

  const installJira = async () => {
    try {
      // grab the HMAC protected state (encodes OrgID) before hitting the reflector
      const state = (await client.beginJiraInstall({})).state;
      const url = `${appReflector()}/integrations/jira/install?state=${state}&next=${jiraNextUrl()}`;
      window.location.href = url;
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.InvalidArgument) {
        setError(err.rawMessage);
      } else {
        setError("Unknown error");
      }
    }
  };

  const installAmazon = async () => {
    try {
      const intg = await client.createAWSIntegration({});
      navigate(`/admin/aws/${intg.integrationId}`);
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.InvalidArgument) {
        setError(err.rawMessage);
      } else {
        setError("Unknown error");
      }
    }
  };

  const installRegistry = async () => {
    try {
      const intg = await client.createRegistryIntegration({
        name: "New Registry Integration",
        type: RegistryIntegrationType.NPM,
      });
      navigate(`/admin/registries/${intg.integrationId}`);
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.InvalidArgument) {
        setError(err.rawMessage);
      } else {
        setError("Unknown error");
      }
    }
  };

  useEffect(() => {
    document.title = "Add New Integration - EdgeBit";
  }, []);

  function IntegrationCard(props: {
    title: string;
    desc: string;
    onclick: () => Promise<void>;
    image: string;
    children?: React.ReactNode;
  }) {
    return (
      <Box
        sx={{
          flex: "1 1 0px",
          minWidth: "200px",
          maxWidth: "400px",
          padding: "20px",
          margin: "10px",
          border: "2px solid #6096FF",
          borderRadius: "5px",
          background: "#F1F4FD",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box component="img" src={props.image} sx={{ maxWidth: "30px", maxHeight: "30px", marginBottom: "10px" }} />
        <Typography variant="h6" gutterBottom sx={{ flex: "0" }}>
          {props.title}
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ flex: "1" }}>
          {props.desc}
        </Typography>
        <EdgeBitPrimaryButton
          type="submit"
          variant="outlined"
          size="medium"
          sx={{ marginTop: "10px", alignSelf: "baseline" }}
          onClick={props.onclick}
        >
          Activate
        </EdgeBitPrimaryButton>
        {props.children}
      </Box>
    );
  }

  function DocCard(props: { title: string; url: string; image: string }) {
    return (
      <Box
        sx={{
          flex: "1 1 0px",
          minWidth: "350px",
          maxWidth: "350px",
          padding: "20px",
          margin: "10px",
          border: "2px solid #ddd",
          borderRadius: "5px",
          background: "#fff",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={props.image}
          sx={{
            maxWidth: "30px",
            maxHeight: "30px",
            marginRight: "20px",
            alignSelf: "center",
          }}
        />
        <Typography variant="h6" sx={{ flex: "1", lineHeight: "30px", alignSelf: "center" }}>
          {props.title}
        </Typography>
        <Button variant="outlined" size="medium" sx={{ alignSelf: "baseline" }} href={props.url} target="_blank">
          View Guide
        </Button>
      </Box>
    );
  }

  return (
    <BodyWrapper>
      <Typography variant="h4" gutterBottom sx={{ marginTop: "40px" }}>
        Add OAuth Integration
      </Typography>
      <Typography variant="body1" gutterBottom>
        Configure your favorite tools with secure OAuth applications
      </Typography>
      <Container sx={{ mt: 3 }} disableGutters>
        {error && (
          <Alert style={{ marginTop: 11 }} severity="error">
            {error}
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            flexWrap: "wrap",
            marginLeft: "-10px",
          }}
        >
          <IntegrationCard
            title="GitHub"
            desc="Allow developers to see a vulnerability check in PRs. Automated import of new Components from your GitHub repos."
            onclick={installGitHub}
            image={"/logo-github.svg"}
          />

          <IntegrationCard
            title="Jira"
            desc="Create Jira issues when EdgeBit finds a new security vulnerability."
            onclick={installJira}
            image={"/logo-jira.svg"}
          />

          <IntegrationCard
            title="Vanta"
            desc="Sync discovered vulnerabilities with Vanta for compliance reporting."
            onclick={installVanta}
            image={"/logo-vanta.svg"}
          />

          <FeatureFlagGate flag="aws-ecs">
            <IntegrationCard
              title="Amazon"
              desc="Allow EdgeBit to track workloads running on Amazon ECS."
              onclick={installAmazon}
              image={"/logo-aws.svg"}
            />
          </FeatureFlagGate>

          <FeatureFlagGate flag="code-analysis">
            <IntegrationCard
              title="Slack"
              desc="Allow EdgeBit to interact with engineers about AI Dependency Autofix proposals."
              onclick={installSlack}
              image={"/logo-slack.svg"}
            />
          </FeatureFlagGate>

          <IntegrationCard
            title="Registry"
            desc="Configure EdgeBit to use a private content registry."
            onclick={installRegistry}
            image={"/logo-registry.svg"}
          />
        </Box>
      </Container>

      <Typography variant="h4" gutterBottom sx={{ marginTop: "40px" }}>
        Build & CI Providers
      </Typography>
      <Typography variant="body1" gutterBottom>
        EdgeBit works with any CI/CD provider, either as a first party experience or using the CLI.
      </Typography>
      <Container sx={{ mt: 3 }} disableGutters>
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            flexWrap: "wrap",
            marginLeft: "-10px",
          }}
        >
          <DocCard
            title="GitHub Actions"
            url="https://edgebit.io/docs/0.x/install-build-actions/"
            image={"/logo-github.svg"}
          />
          <DocCard
            title="Buildkite"
            url="https://edgebit.io/docs/0.x/install-build-buildkite/"
            image={"/logo-buildkite.png"}
          />
          <DocCard title="AWS CodeBuild" url="https://edgebit.io/docs/0.x/install-codebuild/" image={"/logo-aws.svg"} />
          <DocCard
            title="Docker BuildKit"
            url="https://edgebit.io/docs/0.x/install-build-buildx/"
            image={"/logo-docker.svg"}
          />
          <DocCard
            title="Other Platforms"
            url="https://edgebit.io/docs/0.x/install-build-generic/"
            image={"/icon-code.svg"}
          />
        </Box>
      </Container>
    </BodyWrapper>
  );
};
