import { PlainMessage } from "@bufbuild/protobuf";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkExtra } from "../app/store";
import {
  ProposalAnalysisResult as PBProposalAnalysisResult,
  ProposalAnalysisAssets as PBProposalAnalysisAssets,
  ProposalAnalysisMessage as PBProposalAnalysisMessage,
  ProposalAnalysisMetrics as PBProposalAnalysisMetrics,
  ProposalAnalysisMetricsAttemptFailure as PBProposalAnalysisMetricsAttemptFailure,
  ProposalChanges as PBProposalChanges,
  ProposalCombinedPackage as PBProposalCombinedPackage,
  ProposalVulnerability as PBProposalVulnerability,
  ProposalChangeImpact as PBProposalChangeImpact,
  ProposalCallStackFrame as PBProposalCallStackFrame,
  ProposalDiffAnalysis as PBProposalDiffAnalysis,
  ProposalDiffPackage as PBProposalDiffPackage,
  ProposalDiffFile as PBProposalDiffFile,
  ProposalDiffFileChanges as PBProposalDiffFileChanges,
  ProposalDiffFileStatus as PBProposalDiffFileStatus,
} from "../pb/edgebit/platform/v1alpha/source_repos_pb";
import { Proposal, proposalFromPB } from "./repoDetailSlice";

export type ProposalAnalysisResult = Omit<
  PlainMessage<PBProposalAnalysisResult>,
  "createdAt" | "completedAt" | "assets" | "changes" | "packages" | "analysisMetrics" | "impactDirect" | "impactAll"
> & {
  createdAt: string;
  completedAt: string;
  assets: ProposalAnalysisAssets | undefined;
  changes: ProposalChanges[];
  packages: ProposalCombinedPackage[];
  analysisMetrics: ProposalAnalysisMetrics;
  impactDirect: ChangeImpact[];
  impactAll: ChangeImpact[];
  diffAnalysis: ProposalDiffAnalysis;
};

export type ChangeImpact = {
  callStack: CallStackFrame[];
  targetFilename: string;
  targetLineStart: number;
  targetLineEnd: number;
  targetSymbol: string;
  targetPackage: string;
  targetAnalysis: string;
  targetCategory: string;
  targetSemantic: boolean;
  targetExported: boolean;
  targetDiff: string;
  targetBranchUrl: string;
};

export type CallStackFrame = {
  symbolName: string;
  packageName: string;
  filename: string;
  lineStart: number;
  lineEnd: number;
  urlBase: string;
};

export type ProposalAnalysisAssets = Omit<PlainMessage<PBProposalAnalysisAssets>, "symbolBehavior"> & {
  symbolBehavior: ProposalAnalysisMessage[];
};

export type ProposalChanges = PlainMessage<PBProposalChanges>;
export type ProposalAnalysisMessage = PlainMessage<PBProposalAnalysisMessage>;
export type ProposalCombinedPackage = PlainMessage<PBProposalCombinedPackage>;
export type ProposalVulnerability = PlainMessage<PBProposalVulnerability>;
export type ProposalChangeImpact = PlainMessage<PBProposalChangeImpact>;
export type ProposalCallStackFrame = PlainMessage<PBProposalCallStackFrame>;
export type ProposalDiffAnalysis = PlainMessage<PBProposalDiffAnalysis>;
export type ProposalDiffPackage = PlainMessage<PBProposalDiffPackage>;
export type ProposalDiffFile = PlainMessage<PBProposalDiffFile>;
export type ProposalDiffFileStatus = PBProposalDiffFileStatus;
export type ProposalDiffFileChanges = PlainMessage<PBProposalDiffFileChanges>;

export type ProposalAnalysisMetricsAttemptFailure = PlainMessage<PBProposalAnalysisMetricsAttemptFailure>;
export type ProposalAnalysisMetrics = Omit<PlainMessage<PBProposalAnalysisMetrics>, "attemptsFailures"> & {
  attemptsFailures: { [key: string]: ProposalAnalysisMetricsAttemptFailure };
};

export const proposalAnalysisResultFromPB = (
  pb: PBProposalAnalysisResult | undefined,
): ProposalAnalysisResult | null => {
  if (!pb) {
    return null;
  }

  return {
    ...pb,
    createdAt: pb.createdAt ? String(pb.createdAt.toDate().toISOString()) : "",
    completedAt: pb.completedAt ? String(pb.completedAt.toDate().toISOString()) : "",
    assets: pb.assets
      ? {
          ...pb.assets,
          symbolBehavior: pb.assets.symbolBehavior.map((message) => ({
            ...message,
          })),
        }
      : undefined,
    changes: pb.changes.map(proposalChangesFromPB),
    packages: pb.packages.map(proposalPackagesFromPB),
    analysisMetrics: {
      schema: pb.analysisMetrics?.schema || "<missing>",
      counters: pb.analysisMetrics?.counters || {},
      attemptsFailures: Object.fromEntries(
        Object.entries(pb.analysisMetrics?.attemptsFailures || {}).map(([key, value]) => [key, { ...value }]),
      ),
    },
    impactDirect: changeImpactFromPB(pb.impactDirect),
    impactAll: changeImpactFromPB(pb.impactAll),
    diffAnalysis: diffAnalysisFromPB(pb.diffAnalysis),
  };
};

const diffAnalysisFromPB = (pb: PBProposalDiffAnalysis | undefined): ProposalDiffAnalysis => {
  if (!pb) {
    return {
      packages: [],
    };
  }
  return {
    ...pb,
  };
};

const changeImpactFromPB = (pb: ProposalChangeImpact[]): ChangeImpact[] => {
  let impacts: ChangeImpact[] = [];
  impacts = pb.map((impact) => {
    return {
      ...impact,
      callStack: impact.callStack.map((frame) => {
        return {
          ...frame,
        };
      }),
    };
  });
  return impacts;
};

const proposalChangesFromPB = (pb: PBProposalChanges): ProposalChanges => {
  return {
    ...pb,
  };
};

const proposalVulnerabilityFromPB = (pb: PBProposalVulnerability): ProposalVulnerability => {
  return {
    ...pb,
  };
};

const proposalPackagesFromPB = (pb: PBProposalCombinedPackage): ProposalCombinedPackage => {
  return {
    ...pb,
    fixedVulnerabilities: pb.fixedVulnerabilities.map(proposalVulnerabilityFromPB),
  };
};

type ProposalOverview = {
  proposal: Proposal;
  latestResult: ProposalAnalysisResult | null;
  pendingResult: ProposalAnalysisResult | null;
};

export const fetchOverview = createAsyncThunk<
  ProposalOverview,
  { projectId: string; repoId: string; proposalId: string },
  ThunkExtra
>("proposalDetail/fetchOverview", async ({ projectId, repoId, proposalId }, thunkAPI) => {
  try {
    const { repoClient } = thunkAPI.extra;
    const response = await repoClient.getProposalOverview({ projectId, repoId, proposalId });
    const overview: ProposalOverview = {
      proposal: proposalFromPB(response.proposal!),
      latestResult: proposalAnalysisResultFromPB(response.latestAnalysisResult),
      pendingResult: proposalAnalysisResultFromPB(response.pendingAnalysisResult),
    };

    return overview;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const analyzeProposal = createAsyncThunk<
  void,
  { projectId: string; repoId: string; proposalId: string },
  ThunkExtra
>("proposalDetail/analyzeProposal", async ({ projectId, repoId, proposalId }, thunkAPI) => {
  try {
    const { repoClient } = thunkAPI.extra;
    await repoClient.analyzeProposal({ projectId, repoId, proposalId });

    thunkAPI.dispatch(fetchOverview({ projectId, repoId, proposalId }));
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const postProposalPR = createAsyncThunk<
  void,
  { projectId: string; repoId: string; proposalId: string; analysisResultId: string },
  ThunkExtra
>("proposalDetail/postProposalPR", async ({ projectId, repoId, proposalId, analysisResultId }, thunkAPI) => {
  try {
    const { repoClient } = thunkAPI.extra;
    await repoClient.postProposalPR({ projectId, repoId, proposalId, resultId: analysisResultId });

    thunkAPI.dispatch(fetchOverview({ projectId, repoId, proposalId }));
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

interface ProposalDetailState {
  proposal: Proposal | null;
  latestResult: ProposalAnalysisResult | null;
  pendingResult: ProposalAnalysisResult | null;
  analysisState: "idle" | "analyzing";
  postingPr: boolean;
  status: "idle" | "loading" | "failed";
  connectivity: boolean;
}

const initialState: ProposalDetailState = {
  proposal: null,
  latestResult: null,
  pendingResult: null,
  analysisState: "idle",
  postingPr: false,
  status: "idle",
  connectivity: true,
};

export const proposalDetailSlice = createSlice({
  name: "proposalDetail",
  initialState,
  reducers: {
    setConnectivity: (state, action: PayloadAction<boolean>) => {
      state.connectivity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOverview.pending, (state) => {
        state.status = "loading";
        state.proposal = null;
        state.latestResult = null;
        state.pendingResult = null;
      })
      .addCase(fetchOverview.fulfilled, (state, action) => {
        state.proposal = action.payload.proposal;
        state.latestResult = action.payload.latestResult;
        state.pendingResult = action.payload.pendingResult;
        state.status = "idle";
        state.connectivity = true;
        state.analysisState = action.payload.pendingResult ? "analyzing" : "idle";
      })
      .addCase(fetchOverview.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(analyzeProposal.pending, (state) => {
        state.analysisState = "analyzing";
      })
      .addCase(postProposalPR.pending, (state) => {
        state.postingPr = true;
      })
      .addCase(postProposalPR.fulfilled, (state) => {
        state.postingPr = false;
      })
      .addCase(postProposalPR.rejected, (state) => {
        state.status = "failed";
        state.connectivity = false;
        state.postingPr = false;
      });
  },
});

export const selectConnectivity = (state: RootState) => state.proposalDetail.connectivity;
export const selectProposal = (state: RootState) => state.proposalDetail.proposal;
export const selectLatestResult = (state: RootState) => state.proposalDetail.latestResult;
export const selectPendingResult = (state: RootState) => state.proposalDetail.pendingResult;

export default proposalDetailSlice.reducer;
